import { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
                <NavbarBrand tag={Link} to="/">Optioneer</NavbarBrand>
                {/*<NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />*/}
                {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>*/}
                {/*    <ul className="navbar-nav flex-grow">*/}
                {/*        <NavItem>*/}
                {/*            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                {/*        </NavItem>*/}
                {/*    </ul>*/}
                {/*</Collapse>*/}
            </Navbar>
        </header>
    );
}

export default NavMenu;
