import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, Collapse, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import IFilter from "./Interfaces/IFilter";

interface IFilterProps {
    currentFilter: IFilter;
    isLoadingCalculatedOptionsChain: boolean;
    onInputChange: (filter: IFilter) => void;
}

const Filter = (props: IFilterProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const defaultFilter: IFilter = {
        minStrikePrice: NaN,
        maxStrikePrice: NaN,
        minVolume: NaN,
        minOpenInterest: NaN,
        minPercentReturn: NaN,
        percentLoseLimit: 15
    };
    const { register, handleSubmit, getValues, formState: { errors }, reset } = useForm<IFilter>({
        defaultValues: props.currentFilter,
        shouldFocusError: false
    });
    const { ref: minStrikePrice, ...minStrikePriceRest } = register("minStrikePrice", {
        valueAsNumber: true,
        min: {
            value: 1,
            message: "Must be greater than 0."
        },
        validate: {
            greaterThanMaxStrikePrice: (v) => {
                if (v > getValues().maxStrikePrice) {
                    return "This is higher than the Maximum Strike Price";
                }   
            }
        }
    });
    const { ref: maxStrikePrice, ...maxStrikePriceRest } = register("maxStrikePrice", {
        valueAsNumber: true,
        min: {
            value: 1,
            message: "Must be greater than 0."
        },
        validate: {
            lessThanMinStrikePrice: (v) => {
                if (v < getValues().minStrikePrice) {
                    return "This is lower than the Minimum Strike Price";
                }
            }
        }
    });
    const { ref: deltaLimit, ...deltaLimitRest } = register("percentLoseLimit", {
        valueAsNumber: true,
        min: {
            value: 0,
            message: "Can't be a negative number."
        }
    });
    const { ref: minVolume, ...minVolumeRest } = register("minVolume", {
        valueAsNumber: true,
        min: {
            value: 0,
            message: "Can't be a negative number."
        }
    });
    const { ref: minOpenInterest, ...minOpenInterestRest } = register("minOpenInterest", {
        valueAsNumber: true,
        min: {
            value: 0,
            message: "Can't be a negative number."
        }
    });
    const { ref: minPercentReturn, ...minPercentReturnRest } = register("minPercentReturn", {
        valueAsNumber: true,
        min: {
            value: 0,
            message: "Can't be a negative number."
        }
    });
    const onChange: SubmitHandler<IFilter> = (updatedFilter) => props.onInputChange(updatedFilter);

    return (
        <Form onChange={handleSubmit(onChange)}>
            <Button
                className="mb-2"
                color="primary"
                onClick={() => setCollapsed(!collapsed)}
                disabled={props.isLoadingCalculatedOptionsChain}
            >
                Filters  
                <FontAwesomeIcon
                    className="ms-3 me-1"
                    icon={collapsed ? faCaretDown : faCaretUp}
                />
            </Button>
            <Collapse
                className="mb-2"
                isOpen={collapsed}
            >
                <Card>
                    <CardBody>                          
                        <Row>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="minStrikePrice">
                                        Minimum Strike Price
                                    </Label>
                                    <Input
                                        id="minStrikePrice"
                                        type="number"
                                        invalid={Boolean(errors.minStrikePrice)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={minStrikePrice}
                                        {...minStrikePriceRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.minStrikePrice?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="maxStrikePrice">
                                        Maximum Strike Price
                                    </Label>
                                    <Input
                                        id="maxStrikePrice"
                                        type="number"
                                        invalid={Boolean(errors.maxStrikePrice)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={maxStrikePrice}
                                        {...maxStrikePriceRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.maxStrikePrice?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="deltaLimit">
                                        Probability Lose % Limit
                                    </Label>
                                    <Input
                                        id="deltaLimit"
                                        type="number"
                                        invalid={Boolean(errors.percentLoseLimit)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={deltaLimit}
                                        {...deltaLimitRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.percentLoseLimit?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="minVolume">
                                        Minimum Volume
                                    </Label>
                                    <Input
                                        id="minVolume"
                                        type="number"
                                        invalid={Boolean(errors.minVolume)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={minVolume}
                                        {...minVolumeRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.minVolume?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="minOpenInterest">
                                        Minimum Open Interest
                                    </Label>
                                    <Input
                                        id="minOpenInterest"
                                        type="number"
                                        invalid={Boolean(errors.minOpenInterest)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={minOpenInterest}
                                        {...minOpenInterestRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.minOpenInterest?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="position-relative">
                                    <Label for="minPercentReturn">
                                        Minimum % Return
                                    </Label>
                                    <Input
                                        id="minPercentReturn"
                                        type="number"
                                        invalid={Boolean(errors.minPercentReturn)}
                                        disabled={props.isLoadingCalculatedOptionsChain}
                                        innerRef={minPercentReturn}
                                        {...minPercentReturnRest}
                                    />
                                    <FormFeedback tooltip>
                                        {errors.minPercentReturn?.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    disabled={props.isLoadingCalculatedOptionsChain}
                                    onClick={() => {
                                        const currentFilter: IFilter = { ...getValues() };
                                        const resetFilter: IFilter = { ...defaultFilter, percentLoseLimit: NaN };

                                        if (JSON.stringify(currentFilter) !== JSON.stringify(resetFilter)) {
                                            reset(resetFilter);
                                            onChange(resetFilter);
                                        }
                                    }}
                                >
                                    Clear Filters
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
        </Form>
    );
}

export default Filter;
