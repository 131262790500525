import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "debounce-promise";
import { FC } from "react";
import { components, ControlProps, MenuProps, NoticeProps } from "react-select";
import AsyncSelect from "react-select/async";
import ILookupSymbol from "./Interfaces/ILookupSymbol";
import ISelectOption from "./Interfaces/ISelectOption";

interface IAsyncStockSelectProps {
    onChange: (selectOption: ISelectOption[]) => void
}

const AsyncStockSelect = (props: IAsyncStockSelectProps) => {

    const createStockSymbolOptions = (symbols: string[]) => {
        let stockSymbolOptions: ISelectOption[] = [];

        for (const symbol of symbols) {
            const labelName: string = symbol.toUpperCase();
            const valueName: string = symbol.toUpperCase();
            stockSymbolOptions.push({ label: labelName, value: valueName });
        }

        return stockSymbolOptions;
    };
    const promiseLoadStockOptions = (symbol: string) =>
        new Promise<ISelectOption[]>(async (resolve, reject) => {
            if (symbol) {
                symbol = symbol.trim();
                if (symbol === '') {
                    reject();
                }

                const apiURL: string = `${process.env.REACT_APP_OPTIONEER_API_URL}/api/options/lookup/${encodeURIComponent(symbol)}`;
                const response: Response = await fetch(apiURL);

                if (response.ok) {
                    if (response.status === 204) {
                        resolve([]);
                    }
                    const lookupSymbol: ILookupSymbol = await response.json();
                    resolve(createStockSymbolOptions(lookupSymbol.symbols));
                }
                else {
                    reject();
                }
            }
        });
    const wait = 250; // milliseconds
    const debouncedPromiseLoadStockOptions = debounce(promiseLoadStockOptions, wait, { leading: false });

    const Control: FC<ControlProps> = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                <div style={{ marginLeft: "2%" }}>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
                {children}
            </components.Control>
        );
    };

    const NoOptionsMessage = (props: NoticeProps) => {
        return (
            <components.NoOptionsMessage {...props}>
                No results.
            </components.NoOptionsMessage>
        );
    };

    const Menu = (props: MenuProps) => {
        if (props.selectProps.inputValue.length === 0) {
            return null;
        }

        return (
            <components.Menu {...props} />
        );
    };

    return (
        <AsyncSelect
            cacheOptions
            components={{
                Control,
                Menu,
                NoOptionsMessage,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            }}
            placeholder="Search"
            loadOptions={debouncedPromiseLoadStockOptions}
            isMulti={true}
            onChange={(selectOption) => props.onChange(selectOption as ISelectOption[])}
        />
    );
}

export default AsyncStockSelect;