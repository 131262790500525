import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Options from './components/Options';
import './App.css';

function App() {
    return (
        <div>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='/' element={<Options />} />
                    </Routes>
                </Layout>
            </BrowserRouter >
        </div>
    );
}

export default App;
