import { useEffect, useState } from "react";

interface ITimer {
    enabled: boolean
}

const Timer = (props: ITimer) => {
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
            setElapsedTime(previousTime => ++previousTime)
        }, 1000);

        if (!props.enabled) {
            clearInterval(interval);
        }
        
        return () => clearInterval(interval);
    }, [props.enabled]);   

    return (
        <>
            Data current as of {elapsedTime} second(s) ago.
        </>
    );
}

export default Timer;
