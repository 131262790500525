import { faCircleChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Fade, Table, Tooltip } from "reactstrap";
import OptionType from "./Enums/OptionType";
import ICalculatedOption from "./Interfaces/ICalculatedOption";
import ICalculatedOptionsChain from "./Interfaces/ICalculatedOptionsChain";
import IFilter from "./Interfaces/IFilter";

interface IOptionsTableProps {
    filter: IFilter;
    calculatedOptionsChain: ICalculatedOptionsChain[];
    selectedOptionType: OptionType;
    selectedOptionExpirationDate: Date;
}

const OptionsTable = (props: IOptionsTableProps) => {
    const applyFilter = (caculatedOption: ICalculatedOption) => {
        let result = false;

        if (Number.isNaN(props.filter.minStrikePrice) && Number.isNaN(props.filter.maxStrikePrice)) {
            result = true;
        }
        else if (Number.isNaN(props.filter.minStrikePrice)) {
            result = caculatedOption.strike <= props.filter.maxStrikePrice;
        }
        else if (Number.isNaN(props.filter.maxStrikePrice)) {
            result = props.filter.minStrikePrice <= caculatedOption.strike;
        }
        else {
            result = props.filter.minStrikePrice <= caculatedOption.strike && caculatedOption.strike <= props.filter.maxStrikePrice;
        }

        if (Number.isNaN(props.filter.percentLoseLimit)) {
            result = result && true;
        }
        else {
            result = result && caculatedOption.percentLose <= props.filter.percentLoseLimit;
        }

        if (Number.isNaN(props.filter.minVolume)) {
            result = result && true;
        }
        else {
            result = result && props.filter.minVolume <= caculatedOption.volume;
        }

        if (Number.isNaN(props.filter.minOpenInterest)) {
            result = result && true;
        }
        else {
            result = result && props.filter.minOpenInterest <= caculatedOption.openInterest;
        }

        if (Number.isNaN(props.filter.minPercentReturn)) {
            result = result && true;
        }
        else {
            result = result && props.filter.minPercentReturn <= caculatedOption.percentReturn;
        }

        return result;
    };
    const calls: ICalculatedOption[] = props.calculatedOptionsChain.flatMap(o => o.calls).filter((calculatedCalls: ICalculatedOption) => applyFilter(calculatedCalls)).sort((c1, c2) => c2.percentReturn - c1.percentReturn);
    const puts: ICalculatedOption[] = props.calculatedOptionsChain.flatMap(o => o.puts).filter((calculatedPuts: ICalculatedOption) => applyFilter(calculatedPuts)).sort((c1, c2) => c2.percentReturn - c1.percentReturn);
    const calculatedOptions: ICalculatedOption[] = props.selectedOptionType === OptionType.Call ? calls : puts;
    const [percentReturnTooltipOpen, setpercentReturnTooltipOpen] = useState(false);
    const [probabilityLoseTooltipOpen, setprobabilityLoseTooltipOpen] = useState(false);

    const calculatePremiumPerWeek = (optionPrice: number, expirationDate: Date) => {
        const dateDiffMs: number = expirationDate.valueOf() - new Date().valueOf();
        let dateDiffWeeks: number = dateDiffMs / (1000 * 60 * 60 * 24 * 7);
        const weeks: number = Number.isSafeInteger(dateDiffWeeks) ? dateDiffWeeks : Math.round(++dateDiffWeeks);

        return (optionPrice * 100) / weeks;
    };

    return (
        <Fade>
            <Table
                id="table"
                className='table table-striped'
                responsive={true}
                aria-labelledby="Calculated Options"
            >
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Description</th>
                        <th>Strike Price</th>
                        <th>Price</th>
                        <th>Premium/Week</th>
                        <th>Volume</th>
                        <th>Open Interest</th>
                        <th>Implied Volatility</th>
                        <th>
                            <FontAwesomeIcon
                                icon={faCircleChevronDown}
                                className="me-1"
                            />
                            % Return
                            <FontAwesomeIcon
                                id="percentReturnInfo"
                                className="ms-1 text-primary"
                                icon={faCircleInfo} />
                            <Tooltip
                                flip
                                target="percentReturnInfo"
                                isOpen={percentReturnTooltipOpen}
                                toggle={() => setpercentReturnTooltipOpen(!percentReturnTooltipOpen)}
                            >
                                Percent return on the money it would require to secure a Put or cover a Call.
                                <div>
                                    <code>
                                        Premium Received / Cash Required to buy 100 shares or Secure a put
                                    </code>
                                </div>
                            </Tooltip>
                        </th>
                        <th>
                            Probability Lose
                            <FontAwesomeIcon
                                id="probabilityLoseInfo"
                                className="ms-1 text-primary"
                                icon={faCircleInfo} />
                            <Tooltip
                                flip
                                target="probabilityLoseInfo"
                                isOpen={probabilityLoseTooltipOpen}
                                toggle={() => setprobabilityLoseTooltipOpen(!probabilityLoseTooltipOpen)}
                            >
                                The probability of the trade not working in your favor using Delta as a probability indicator.
                            </Tooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {calculatedOptions.map((calculatedOption: ICalculatedOption) =>
                        <tr key={calculatedOption.symbol}>
                            <td>{calculatedOption.symbol}</td>
                            <td>{calculatedOption.description}</td>
                            <td>${calculatedOption.strike.toFixed(2)}</td>
                            <td>${calculatedOption.price.toFixed(2)}</td>
                            <td>${calculatePremiumPerWeek(calculatedOption.price, props.selectedOptionExpirationDate).toFixed(2)}</td>
                            <td>{calculatedOption.volume}</td>
                            <td>{calculatedOption.openInterest}</td>
                            <td>{calculatedOption.greeks ? <>{(calculatedOption.greeks.impliedVolatility * 100).toFixed(2)}%</> : ""}</td>
                            <td>{calculatedOption.percentReturn.toFixed(2)} %</td>
                            <td>{calculatedOption.greeks ? <>{calculatedOption.percentLose.toFixed(2)}%</> : ""}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Fade>
    );
}

export default OptionsTable;
