import { useState } from 'react';
import { Container, Row, Col, Spinner, Alert, ButtonGroup, Button } from 'reactstrap';
import Select from 'react-select';
import ICalculatedOptionsChain from './Interfaces/ICalculatedOptionsChain';
import OptionType from './Enums/OptionType';
import IFilter from './Interfaces/IFilter';
import ISelectOption from './Interfaces/ISelectOption';
import Filter from './Filter';
import AsyncStockSelect from './AsyncStockSelect';
import OptionsTable from './OptionsTable';
import Timer from './Timer';
import IQuote from './Interfaces/IQuote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import IOptionExpirations from './Interfaces/IOptionExpirations';

const Options = () => {
    const [selectedSymbols, setSelectedSymbols] = useState<ISelectOption[] | null>(null);
    const [quotes, setQuotes] = useState<IQuote[] | null>(null);
    const [selectedOptionExpiration, setSelectedOptionExpiration] = useState<ISelectOption | null>(null);
    const [selectedOptionType, setSelectedOptionType] = useState<OptionType>(OptionType.Call);
    const [optionExpirations, setOptionExpirations] = useState<ISelectOption[] | null>(null);
    const [optionExpirationsDisabled, setOptionExpirationsDisabled] = useState(true);
    const [calculatedOptionsChain, setCalculatedOptionsChain] = useState<ICalculatedOptionsChain[] | null>(null);
    const [isLoadingOptionExpirations, setLoadingOptionExpirations] = useState(false);
    const [isLoadingCalculatedOptionsChain, setLoadingCalculatedOptionsChain] = useState(false);
    const [filter, setFilter] = useState<IFilter>({
        minStrikePrice: NaN,
        maxStrikePrice: NaN,
        minVolume: NaN,
        minOpenInterest: NaN,
        minPercentReturn: NaN,
        percentLoseLimit: 15
    });   
    const [hasErrors, setErrors] = useState(false);

    const getAndSetQuotes = async (selectOptions: ISelectOption[]) => {
        const symbols: string = selectOptions.map(o => o.value).join(",");
        const apiURL: string = `${process.env.REACT_APP_OPTIONEER_API_URL}/api/stock/${encodeURIComponent(symbols)}`;
        const response: Response = await fetch(apiURL);

        if (response.ok) {
            if (response.status === 204) {
                setQuotes(null);
                return;
            }

            const stocks: IQuote[] = await response.json();

            setQuotes(stocks);
        }
    };

    const getOptionExpirations = async (symbolsSelectOptions: ISelectOption[]): Promise<Date[] | null> => {
        setCalculatedOptionsChain(null);
        setSelectedOptionExpiration(null);
        setOptionExpirations(null);
        setOptionExpirationsDisabled(true);
        setLoadingOptionExpirations(true);

        const symbols: string = symbolsSelectOptions.map(o => o.value).join(",");
        const apiURL: string = `${process.env.REACT_APP_OPTIONEER_API_URL}/api/options/${encodeURIComponent(symbols)}/expirations`;
        const response: Response = await fetch(apiURL);

        if (response.ok) {
            if (response.status === 204) {
                setLoadingOptionExpirations(false);
                return null;
            }

            const optionExpirationsResult: IOptionExpirations = await response.json();
            const optionExpirationDates: Date[] = optionExpirationsResult.expirationDates.map(expirationDate => new Date(expirationDate));

            setOptionExpirationsDisabled(false);
            setErrors(false);
            setLoadingOptionExpirations(false);

            return optionExpirationDates;
        }
        else {
            setErrors(true);
            setLoadingOptionExpirations(false);
            return null;
        }
    };

    const getSelectOptionExpirations = async (symbolsSelectOptions: ISelectOption[]): Promise<ISelectOption[] | null> => {
        const optionExpirationDates: Date[] | null = await getOptionExpirations(symbolsSelectOptions);

        if (optionExpirationDates) {
            return createOptionExpirations(optionExpirationDates);
        }

        return null;
    }

    const setExpirations = (selectOptionsExpirations: ISelectOption[]) => {
        setOptionExpirationsDisabled(true);
        setLoadingOptionExpirations(true);
        setOptionExpirations(selectOptionsExpirations);
        setOptionExpirationsDisabled(false);
        setLoadingOptionExpirations(false);
    };

    const createOptionExpirations = (optionExpirations: Date[]): ISelectOption[] => {
        const expirations: ISelectOption[] = [];
        const dateFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

        for (const expiration of optionExpirations) {
            const labelName = expiration.toLocaleDateString("en-US", dateFormat);
            const valueName = expiration.toLocaleDateString("en-US", dateFormat);
            expirations.push({ label: labelName, value: valueName });
        }

        return expirations;
    };

    const getAndSetCalculatedOptionsChain = async (selectOptions: ISelectOption[], expiration: string) => {
        setCalculatedOptionsChain(null);
        setLoadingCalculatedOptionsChain(true);

        const symbols: string = selectOptions.map(o => o.value).join(",");
        const apiURL: string = `${process.env.REACT_APP_OPTIONEER_API_URL}/api/options/${encodeURIComponent(symbols)}/${expiration}/calculatedOptionsChain`;
        const response: Response = await fetch(apiURL);

        if (response.ok) {
            let calculatedOptionsChain: ICalculatedOptionsChain[] = await response.json();

            calculatedOptionsChain = calculatedOptionsChain.filter(c => c.calls.length > 0 && c.puts.length > 0);
            calculatedOptionsChain.length > 0 ? setCalculatedOptionsChain(calculatedOptionsChain) : setCalculatedOptionsChain(null);

            setErrors(false);
        }
        else {
            setErrors(true);
        }

        setLoadingCalculatedOptionsChain(false);
    };

    const SelectExpirations = () => (
        <Select
            components={{
                IndicatorSeparator: () => null
            }}
            options={optionExpirations ?? []}
            onChange={async (selectOption) => {
                const expiration: ISelectOption = selectOption as ISelectOption;
                setSelectedOptionExpiration(expiration);

                if (selectedSymbols) {
                    await getAndSetCalculatedOptionsChain(selectedSymbols, expiration.value);
                }
            }}
            value={{ label: `Expiring ${selectedOptionExpiration?.label}`, value: selectedOptionExpiration?.value }}
            isDisabled={optionExpirationsDisabled}
            isSearchable={false}
            isLoading={isLoadingOptionExpirations}
        />
    );

    return (
        <Container>
            <Row>
                <Col>
                    <AsyncStockSelect
                        onChange={async (symbolsSelectOptions: ISelectOption[]) => {

                            if (symbolsSelectOptions.length > 0) {
                                await getAndSetQuotes(symbolsSelectOptions);

                                setSelectedSymbols(symbolsSelectOptions);

                                const selectOptionsExpirations: ISelectOption[] | null = await getSelectOptionExpirations(symbolsSelectOptions);

                                if (selectOptionsExpirations) {
                                    const expiration: ISelectOption = selectedOptionExpiration ?? selectOptionsExpirations[0];

                                    setExpirations(selectOptionsExpirations);
                                    setSelectedOptionExpiration(expiration);

                                    await getAndSetCalculatedOptionsChain(symbolsSelectOptions, expiration.value);
                                }
                            }
                            else {
                                setSelectedSymbols(null);
                                setCalculatedOptionsChain(null);
                                setSelectedOptionExpiration(null);
                                setOptionExpirations(null);
                                setOptionExpirationsDisabled(true);
                                setErrors(false);
                            }
                        }}
                    />
                </Col>
                <Col>{!optionExpirationsDisabled && <SelectExpirations />}</Col>
            </Row>
            <Row>
                <Col>
                    <div className="mt-2">
                        {hasErrors && <Alert color="danger">Something went wrong!</Alert>}
                    </div>
                </Col>
                <Col></Col>
            </Row>
            {selectedSymbols && selectedOptionExpiration &&
                <>
                <Row>
                    <Col>
                        <Filter
                            currentFilter={filter}
                            isLoadingCalculatedOptionsChain={isLoadingCalculatedOptionsChain}
                            onInputChange={(updatedFilter: IFilter) => setFilter(updatedFilter)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonGroup
                            style={{ zIndex: 0 }}
                        >
                            <Button
                                color="primary"
                                active={selectedOptionType === OptionType.Call}
                                outline={selectedOptionType !== OptionType.Call}
                                onClick={() => setSelectedOptionType(OptionType.Call)}
                                disabled={isLoadingCalculatedOptionsChain}
                            >
                                Call
                            </Button>
                            <Button
                                color="primary"
                                active={selectedOptionType === OptionType.Put}
                                outline={selectedOptionType !== OptionType.Put}
                                onClick={() => setSelectedOptionType(OptionType.Put)}
                                disabled={isLoadingCalculatedOptionsChain}
                            >
                                Put
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                </>
            }
            <Row className="mt-2">
                <Col>
                    {!isLoadingOptionExpirations && !isLoadingCalculatedOptionsChain && selectedSymbols && 
                        quotes?.map(q => 
                            <Alert key={q.symbol} color="info">
                                <div><strong>Symbol: </strong>{q?.symbol}</div>
                                <div><strong>Price: </strong>${q?.price.toFixed(2)}</div>
                                <div><strong>Description:</strong> {q?.description}</div>
                                {calculatedOptionsChain && calculatedOptionsChain.some(c => c.underlying.symbol === q.symbol) ? "" : <div><strong>{q.symbol} has no Options{optionExpirations && <> for this expiration date</>}.</strong></div>}
                            </Alert>)
                    }
                </Col>
                <Col>
                    {!isLoadingCalculatedOptionsChain && calculatedOptionsChain &&
                        <Alert color="primary">
                            <FontAwesomeIcon
                                style={{ cursor: 'pointer' }}
                                className="me-1 text-primary"
                                icon={faRefresh}
                                onClick={async () => {

                                    if (selectedSymbols) {
                                        await getAndSetQuotes(selectedSymbols);

                                        const selectOptionsExpirations: ISelectOption[] | null = await getSelectOptionExpirations(selectedSymbols);

                                        if (selectOptionsExpirations && selectedOptionExpiration) {
                                            setExpirations(selectOptionsExpirations);
                                            setSelectedOptionExpiration(selectedOptionExpiration);

                                            await getAndSetCalculatedOptionsChain(selectedSymbols, selectedOptionExpiration.value)
                                        }
                                    }
                                }}
                            />
                            <Timer enabled={!isLoadingCalculatedOptionsChain && calculatedOptionsChain !== null} />
                        </Alert>
                    }
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                {isLoadingCalculatedOptionsChain
                    ? selectedSymbols?.some(s => s.value === "BB")
                        ? <img
                            src="https://acegif.com/wp-content/uploads/2021/4fh5wi/pepefrg-5.gif"
                            className="rounded-circle"
                            style={{ width: "8rem", height: "6rem", marginTop: "10rem" }}
                            alt="lol BB"
                        />
                        : <Spinner
                            style={{
                                color: selectedSymbols?.some(s => s.value === "GME") ? "purple" : "blue",
                                width: '3rem',
                                height: "3rem",
                                marginTop: "10rem"
                            }} />
                    : calculatedOptionsChain && selectedOptionExpiration &&
                    <OptionsTable
                        filter={filter}
                        calculatedOptionsChain={calculatedOptionsChain}
                        selectedOptionType={selectedOptionType}
                        selectedOptionExpirationDate={new Date(selectedOptionExpiration.value)}
                    />
                }
            </Row>
        </Container>
    );
}

export default Options;
