import { ReactElement } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

const Layout = (props: { children: ReactElement; }) => {
    return (
        <div>
            <div className="ng-white border-bottom box-shadow mb-3">
                <Container>
                    <NavMenu />
                </Container>
            </div>
            <Container>
                {props.children}
            </Container>
        </div>
    );
}

export default Layout;
